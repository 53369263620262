export const APP_NAME = 'FRESHMAP_WINDTRE'

// routing
export const ROUTE_AUTH = 'ROUTE_AUTH'
export const ROUTE_CHANGE_PASSWORD = 'ChangePassword'
export const ROUTE_CHECKOUT = 'Checkout'
export const ROUTE_PAYMENT_METHODS = 'PaymentMethods'
export const ROUTE_CONTRACTOR = 'Contractor'
export const ROUTE_COOKIES = 'Cookies'
export const ROUTE_GROUP_DETAIL = 'ROUTE_GROUP_DETAIL'
export const ROUTE_GROUPS = 'ROUTE_GROUPS'
export const ROUTE_BUSINESS = 'ROUTE_BUSINESS'
export const ROUTE_LOGIN = 'Login'
export const ROUTE_PAY_BY_LINK = 'PosPayByLink'
export const ROUTE_BO_PAY_BY_LINK = 'BoPayByLink'
export const ROUTE_POLICIES = 'Policies'
export const ROUTE_QUOTES = 'Quotes'
export const ROUTE_QUOTATION_DATA = 'QuotationData'
export const ROUTE_SUMMARY_DATA = 'Summarydata'
export const ROUTE_MAINTENANCE_PAGE = 'MaintenancePage'
export const ROUTE_PAGE_NOT_FOUND = 'PageNotFound'
export const ROUTE_USER_INFO = 'UserInfo'
export const ROUTE_PRIVACY_CONSENT = 'PrivacyConsent'

// UI EVENTS
export const SHOW_LOADING_PANEL = 'SHOW_LOADING_PANEL'
export const DISMISS_LOADING_PANEL = 'DISMISS_LOADING_PANEL'
export const RESET_LOADING_PANEL = 'RESET_LOADING_PANEL'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'


// PAYMENT GATEWAYS
export const GATEWAY_BRAINTREE = 'Braintree'
export const GATEWAY_WEFOXPAY = 'WefoxPay'

// PAYMENT GATEWAYS TYPES (SUBGATEWAYS)
export const GATEWAY_BRAINTREE_CARD = 'American Express/Visa Mastercard'
export const GATEWAY_WEFOXPAY_SDD = 'Wefox Pay SDD'
export const GATEWAY_WEFOXPAY_ONESHOT = 'Wefox Pay ONESHOT'

// PAYMENT INSTALMENT (FREQUENCY OF PAYMENT)
export const MONTHLY = 'monthly'
export const ANNUAL = 'annual'
export const SPOT = 'spot'

// LOGIN MODALS
export const LOGIN_ELEMENT = 'LoginElement'
export const FORGET_PASSWORD_ELEMENT = 'ForgetPasswordElement'
export const MOD_PASSWORD_ELEMENT = 'ModPasswordElement'
